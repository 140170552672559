.errorPage{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #18a3ae;
    /* position: absolute; */
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.errorPage__status{
    font-size: 16rem;
    color: white;
    font-weight: 700;
    
}
.errorPage__message{
    font-size: 3rem;
    color: white;
    font-weight: 600;
}

.errorPage__button{
    background-color: white;
    color: #18a3ae;
    padding: 1.2rem 0.8rem;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 1rem;
    cursor: pointer;
    transition: all ease-in-out 0.5s;
}
.errorPage__button:hover{
    background-color: rgb(227, 227, 227);
    color: #158891;
    font-size: 1.3rem;
}