.copyright a {
  color: #18a3ae;
  text-decoration: none;
}
.footer {
  font-size: 0.8rem;
  background: rgb(248, 249, 250);
  /* height: 15rem; */
}
.socialIcon {
  /* padding: 0.2rem; */
  /* height: fit-content; */
  /* width: 100%; */
  margin: 0.4rem;
  background: #18a3ae;
  color: white;
  border-radius: 20%;
}
.iconFooter {
  margin: 0.4rem;
  /* font-size: 1rem; */
  background: #18a3ae;
  color: white;
}
