
.cartContainer {
  height: 100%;

  width: 100%;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.cart {
  margin: 0 4.6rem;
}
h1,
h3 {
  color: var(--color-bg);
}
.header {
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: start;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.productImage img {
  width: 15rem;
  height: 12rem;
}
.productImage {
  width: 100%;
  height: 100%;
}
.productQuantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
.productQuantity button {
  color: var(--color-bg);
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50px;
  border: none;
  font-size: 1.5rem;
}
.productQuantity button:hover,
.cancel:hover {
  color: #ffd300;
}
.checkoutBtn {
  border: none;
  background: var(--color-bg);
  color: white;
  cursor: pointer;
}
.checkoutBtn:hover {
  background: #ffdd00;
}
.checkoutContainer {
  box-shadow: 0 2px 7px #dfdfdf;
  border-radius: 25px;
  margin-bottom: 1rem;
}
.summary {
  margin: 0 0rem;
  box-shadow: 0 2px 7px #dfdfdf;
  border-radius: 25px;
}
/* Scrollbar */
#modalScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

#modalScrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#modalScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--color-bg);
}
/* <----------------Media Queries for small  Device------------------> */
@media (max-width: 600px) {
  .cart {
    margin: 0 0;
  }
}
.cart_emptyCartDiv{
  width: 100%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-bg);
  font-size: 3rem;
  font-weight: 600;
}