:root {
  --color-bg: #18a3ae;
}
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

a {
  color: black;
  background-color: transparent;
  text-decoration: none;
}
li {
  text-decoration: none;
  list-style: none;
}
ul,
li {
  list-style-type: none;
  list-style: none;
}
li::after {
  list-style-type: none;
  list-style: none;
}
li:focus {
  list-style-type: none;
  list-style: none;
}
ul::after {
  list-style-type: none;
  list-style: none;
}
ul:focus {
  list-style-type: none;
  list-style: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.loaderNone {
  display: none;
}
button:active {
  outline: none;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* <----------------Media Queries for Mobile Phones------------------> */
@media (max-width: 650px) {
  html {
    font-size: 60%;
  }
}
/* <----------------Media Queries for Medium  Device------------------> */
@media (max-width: 820px) {
  html {
    font-size: 80%;
  }
}
/* <----------------Media Queries for large  Device------------------> */
@media (max-width: 1200px) {
  html {
    font-size: 80%;
  }
}
