@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.productCard {
  position: relative;
}
.mainContainer {
  perspective: 1000px;
  border-radius: 3rem 3rem;
  margin: 3rem 1rem;
}
.card {
  border-radius: 3rem 3rem;
  transform-style: preserve-3d;
  transition: all 0.7s ease;
  /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    margin-bottom: 1rem; */
  box-shadow: #18a4ae3e 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  margin-bottom: 1rem;
}
.outOfStock {
  z-index: 1;

  padding: 2rem 0;
  width: 100%;
  position: absolute;
  top: 11rem;
  text-align: center;
}
.outOfStock-backgroound {
  /* z-index: 1; */
  opacity: 0.6;

  padding: 2rem 0;
  width: 100%;
  position: absolute;
  top: -1px;
  text-align: center;
  background-color: #474545;
}
.outOfStock-div {
  color: #e1e1e1;
  width: 100%;
  display: flex;
  top: 10px;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  position: absolute;
  text-align: center;
  font-weight: 400;
}

.product-card {
  width: 23rem;
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;

  border-radius: 3rem 3rem;
  backface-visibility: hidden;
}

.cardBack {
  position: absolute;
  width: 23rem;
  height: 35.4rem;
  display: flex;
  justify-content: center;
  text-align: center;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  flex-direction: column;
  justify-content: space-between;
}
.product-card-inner {
  transform-style: preserve-3d;
  transition: all 0.4s ease;
  perspective: 1000px;
}
.mainContainer:hover .card {
  transform: rotateY(180deg);
}
/* .card {
  transform: rotateY(180deg);
} */

.badge {
  position: absolute;
  left: 0;
  top: 1rem;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 700;

  color: #fff;
  padding: 0.2rem 0.6rem;
}

.product-tumb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23rem;
  padding: 2rem;
  background: #f7f7f7;
  border-radius: 3rem 3rem 0 0;
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  padding: 30px;
  background: white;
  border-radius: 0 0 3rem 3rem;
}

.product-catagory {
  display: block;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 1rem;
}

.product-details h4 a {
  font-weight: 500;
  display: block;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: #363636;
  text-decoration: none;
  transition: 0.2s;
}

.product-details h4 a:hover {
  color: #fbb72c;
}

.product-details p {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 1rem;
  color: #999;
}

.product-bottom-details {
  overflow: hidden;
  border-top: 1px solid #eee;
  padding-top: 1.2rem;
}

.product-bottom-details div {
  float: left;
  width: 50%;
}

.product-price {
  font-size: 1rem;
  color: #18a3ae;
  font-weight: 600;
}

.product-price small {
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 0.2rem;
}

.product-links {
  text-align: right;
}

.product-links a {
  display: inline-block;
  margin-left: 0.2rem;
  color: #e1e1e1;
  transition: 0.2s;
  font-size: 1rem;
}

.product-links a:hover {
  color: #fbb72c;
}
.addCart {
  width: 23rem;
  border-radius: 0 0 3rem 3rem;
  padding: 1rem 0;
  /* background: #fbb72c; */
  color: #f0f0f0;
  border: none;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.quantity {
  /* padding: 1rem 0rem; */
  /* width: 23rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
}
.quantity button {
  background-color: var(--color-bg);

  color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: none;
  font-size: 2rem;
}
.decre {
  margin-right: 1rem;
}
.incre {
  margin-left: 1rem;
}
.cardBackProduct-details {
  display: flex;
  padding: 2rem 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}
.cardBackProduct-details p {
  text-align: left;
}

/* new CSS */
.productCard {
  width: 25vw;
  height: 35vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  /* padding: 0.5rem; */
  border: 1px rgb(214, 214, 214) solid;
  margin: 1rem 1.5rem;
}
.productCart__image__container {
  /* flex: .4; */
  width: 100%;
  height: 60%;
  border-radius: 20px;
  padding-top: 0.5rem;
  cursor:pointer;
  /* background: #f7f7f7; */
}
.productCart__image__container img {
  max-width: 20rem;
  height: 15rem;
  /* max-width: 100%;
  max-height: 100%; */
  border-radius: 10px;
}
.productCart__details {
  width: 100%;
  /* flex: .6; */
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.productCart__details__title {
  width: 100%;
  /* flex: 0.2; */
  height: 20%;
  font-size: 1.5rem;
  padding: 0.1rem 0 0.5rem;
  /* background-color: blue; */
}
.productCart__details__dect {
  /* flex: 0.3; */
  height: 30%;
  padding: 0.1rem 2rem 0.5rem;

  text-align: justify;
}
.productCart__details__events {
  /* flex:0.2; */
  height: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-weight: 600;
}
.productCart__details__cost {
  color: #18a3ae;
}
.productCart__details__cost span {
  margin-right: 0.5rem;
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
}
.productCart__details__addToCart {
  /* flex: 0.2; */
  height: 30%;
  width: 100%;
  /* padding: 0.2rem 0; */
  /* background: #fbb72c; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #f0f0f0;
  border: none;
  font-size: 1.3rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--color-bg);
  cursor: pointer;
}
.productCart__details__addToCart:hover {
  background-color: #158a92;
}
.errorMessage {
  color: #ff0000;
  font-size: 0.8rem;
}

.carousel-item {
  padding: 5rem;
}
.carousel-control-prev,
.carousel-control-next {
  color: #18a3ae;
  font-size: 2rem;
  transition: all 0.2s ease-in;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: #18a3ae;
  font-size: 3rem;
}
@media only screen and (max-width: 1000px) {
  .productCard {
    width: 35vw;
    height: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;

    border: 1px rgb(214, 214, 214) solid;
    margin: 1.5rem 0.25rem;
  }
}
@media only screen and (max-width: 600px) {
  .productCard {
    width: 80vw;
    height: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    /* padding: 0.5rem; */
    border: 1px rgb(214, 214, 214) solid;
    margin: 1.5rem 0;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .productCard {
    width: 80vw;
    height: 120vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    /* padding: 0.5rem; */
    border: 1px rgb(214, 214, 214) solid;
    margin: 1.5rem 0;
  }
}
