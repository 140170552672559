.userAddress {
  z-index: 10;
  position: fixed;
  top: -2000px;
  /* top: 0px; */
  /* bottom: 2000px; */
  left: 50%;
  transform: translate(-50%, -50%);

  width: 40vw;
  height: 80vh;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1.5px rgb(214, 214, 214) solid;
  /* padding: 1rem; */
  background-color: white;
  transition: all 1s ease-in-out;
}

.userAddress__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem 1rem;
  /* background-color: brown; */
  border-bottom: 1px rgb(214, 214, 214) solid;
  background-color: #18a3ae;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.userAddress__header-text {
  color: white;
  font-size: 1.8rem;
  text-transform: capitalize;
  font-weight: 600;
}

.userAddress__body {
  flex: 1;
  width: 100%;
  height: 100%;
  border-bottom: 1px rgb(214, 214, 214) solid;
  padding: 1rem 1.5rem 0.5rem;
}
.userAddress__footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem 4rem 1rem;
  background-color: #18a3ae;
  /* background-color: brown; */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.userAddress__footer div {
  font-size: 1.2rem;
  /* font-weight: 600; */
  background-color: white;
  color: black;
  padding: 5px 20px;
  color: #18a3ae;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  border: 1px rgb(214, 214, 214) solid;
}

@media only screen and (max-width: 1000px) {
  .userAddress {
    z-index: 10;
    position: absolute;
    top: -2000px;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 90%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1.5px rgb(214, 214, 214) solid;
    padding: 1rem;
    background-color: white;
    transition: all 1s ease-in-out;
  }
}
