.invoice-container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem 0;
  /* width: 100vw; */
  /* border: 1px solid rgb(152, 151, 151); */
  overflow: scroll;
}
.invoice-container::-webkit-scrollbar {
  display: none;
}
.invoice-heading {
  font-size: 1.5rem;
}
.invoice-Sub-heading {
  font-size: 1.3rem;
}
.invoice-para {
  font-size: 0.6rem;
}

.invoice {
  max-width: 16cm;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(188, 186, 186);
  border-top: 5px solid #18a3ae;
  border-bottom: 5px solid#18A3AE;
  padding: 2rem 2rem;
}
.invoice-header {
  display: flex;
  flex-direction: column;
}
.invoice-header .heading,
.clientDetails {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.address,
.taxDetails,
.client {
  width: 40%;
}
.address,
.client {
  text-align: left;
}
.taxDetails {
  text-align: end;
}
.miniHeading {
  color: #18a3ae;
  font-weight: bold;
}
.billTable {
  padding: 2rem 0rem 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

table {
  border: 1px solid rgb(179, 176, 176);
  /* list-style:lower-greek; */
}
tr {
  text-align: center;
}

tr :nth-child(2) {
  padding: 5px;
  text-align: left;
}
tr :nth-child(5) {
  text-align: left;
  padding: 5px;
}
tr :nth-child(6) {
  text-align: right;
  padding: 5px;
}
#tableHead td {
  text-align: center;
}
.invoiceFooter {
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  /* align-items: flex-end; */
  position: relative;
}
.tableBottom td:nth-child(1) {
  text-align: left;
  padding: 0 0.5rem;
}
.tableBottom td:nth-child(2) {
  padding: 0 0.5rem;
  text-align: right;
}
.invoiceFooterDetails {
  position: absolute;
  bottom: 0;
  left: 0;
}
.invoiceFooterTable {
  justify-content: flex-end;
  align-items: flex-end;
}

.b {
  margin: 0.5rem 0;
  cursor: pointer;
}

.download {
  margin: 1rem 1rem;
  transition: all 0.3s ease-in-out;
}
.download a {
  padding: 0.2rem 0.4rem;
  border-radius: 25px;
  background-color: #18a3ae;
  text-decoration: none;
  outline: none;
  color: white;
}

.download:hover {
  transform: scale(1.1);
}

/* span {
  font-size: 1rem;
} */
@media (max-width: 720px) {
  .invoice html {
    font-size: 10%;
  }
  /* html {
    font-size: 50%;
  } */
}
/* @media (max-width: 500px) {
  html {
    font-size: 60%;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 50%;
  } */
/* } */
