.downloadInvoice {
  margin: 0.5rem 0;
  cursor: pointer;
}
/* .downloadInvoice a {
  padding: 0.2rem 0.4rem;
  border-radius: 25px;
  background-color: #18a3ae;
  text-decoration: none;
  outline: none;
  color: white;
} */
/* .downloadInvoice a:hover {
  border: 1px solid #18a3ae;
  color: #18a3ae;
  background-color: white;
} */
#download {
  color: white;
  text-decoration: none;
}

#download :hover {
  color: white;
  text-decoration: none;
}
