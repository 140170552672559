.uiElement_button {
  background: #18a3ae;
  /* display: inline-block; */
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  margin-top: 33px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}
.uiElement_button:hover {
  background-color: #14838b;
}

.uiElement_loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #18a3ae; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading_div {
  width: 100vw;
  height: 80vh;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.linkTag {
  text-decoration: dotted;
  display: flex;
  align-items: center;
  justify-content: baseline;
}

/* Layout */

li {
  margin: 0 2rem;
  color: black;
}

.linkTag {
  display: block;
  position: relative;
  padding: 0.2em 0;
}
.linkTag:link {
  color: #18a3ae;
  background-color: transparent;
  text-decoration: none;
}

/* Fade in */
.linkTag::after {
  content: "";
  position: absolute;

  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: #18a3ae;

  opacity: 0;
  transition: opacity 500ms, transform 300ms;
  text-decoration: none;
}

.linkTag:hover::after,
.linkTag:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

/* Slide in */
.linkTag {
  overflow: hidden;
}

.linkTag::after {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.linkTag:hover::after,
.linkTag:focus::after {
  transform: translate3d(0, 0, 0);
}
.linkTag:hover {
  color: #18a3ae;
}
