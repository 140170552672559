.modal-backdrop {
  /* background: rgb(24, 163, 174) !important; */
  /* backdrop-filter: blur(5px); */
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.modal-header {
  border-bottom: none;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
}
label {
  font-family: "Raleway", sans-serif;
  font-size: 11pt;
}
#forgot-pass {
  color: #18a3ae;
  font-family: "Raleway", sans-serif;
  font-size: 10pt;
  margin-top: 3px;
  text-align: right;
}
#card {
  background: #fbfbfb;
  border-radius: 8px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
  height: 410px;
  margin: 6rem auto 8.1rem auto;
  width: 329px;
}
#card-content {
  padding: 12px 44px;
}
#card-title {
  font-family: "Raleway Thin", sans-serif;
  letter-spacing: 4px;
  padding-bottom: 23px;
  padding-top: 13px;
  text-align: center;
}
#signup {
  color: #18a3ae;
  font-family: "Raleway", sans-serif;
  font-size: 10pt;
  margin-top: 16px;
  text-align: center;
}
#submit-btn {
  background: -webkit-linear-gradient(right, rgb(159, 212, 247), #41d7e2);
  border: none;
  border-radius: 21px;
  box-shadow: 0px 1px 8px #79bfee;
  cursor: pointer;
  color: white;
  font-family: "Raleway SemiBold", sans-serif;
  height: 42.3px;
  margin: 0 auto;
  margin-top: 50px;
  transition: 0.25s;
  width: 153px;
}

#submit-btn,
button:active,
button:focus {
  color: white;
  outline: none;
}
#submit-btn:hover {
  box-shadow: 0px 1px 18px #18a3ae;
}
.form {
  align-items: left;
  display: flex;
  flex-direction: column;
}
.form-border {
  background: -webkit-linear-gradient(right, #98d0f6, #18a3ae);
  height: 1px;
  width: 100%;
}
.form-content {
  background: #fbfbfb;
  border: none;
  outline: none;
  padding-top: 14px;
}
.underline-title {
  background: -webkit-linear-gradient(right, #98d0f6, #18a3ae);
  height: 2px;
  margin: -1.1rem auto 0 auto;
  width: 89px;
}
.wrongData {
  content: "Check Details Agian";
  border: 1px solid red;
}
.writeData {
  content: "Check Details Agian";
  border: 1px solid rgb(179, 255, 0);
}
.cancelbutton span {
  transition: all 0.3 ease-in;
  cursor: pointer;
}
.cancelbutton span:hover {
  color: #41d7e2;
}
.passwordEye {
  position: absolute;
  color: #18a3ae;
  font-size: 1.5rem;
  width: fit-content;
  right: 0;
  top: 1rem;
}
.passwordInput {
  background: none;
  border: none;
  outline: none;
  padding-top: 14px;
}
.form-content {
  background: none;
  border: none;
  outline: none;
  padding-top: 14px;
}
.password-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
}
.password-wrapper i {
  width: 700px;
}
