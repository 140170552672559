.previousOrderPage{
    /* width: 100vw; */
    min-height: 100vh;

}
.previousOrderPage__orderList {
    /* margin: 0 4.6rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
.previousOrderPage__header{
    border-bottom: 1px rgb(214, 214, 214) solid;
    margin-bottom: 2rem;
}
.previousOrderPage__order{
    min-width: 60vw;
    max-width: 60vw;
    border: 1px rgb(214, 214, 214) solid;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.previousOrderPage__order_image{
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    border: 1px rgb(214, 214, 214) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #18A3AE; */
    margin-right: 2rem;
}
.previousOrderPage__order_details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.previousOrderPage__order_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: rgb(230, 230, 230); */
    background-color: #18a3ae;
    color: white;
    padding: 0.2rem 2rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

}
.previousOrderPage__order_body{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.previousOrderPage__order_body-top{
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.previousOrderPage__order_body-mid{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media only screen and (max-width: 1000px){
    .previousOrderPage__order{
        min-width: 80vw;
        max-width: 80vw;
        border: 1px rgb(214, 214, 214) solid;
        border-radius: 10px;
        margin-bottom: 1.5rem;
        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
        rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    }
}

@media only screen and (max-width: 700px){
    .previousOrderPage__order{
        min-width: 90vw;
        max-width: 90vw;
        border: 1px rgb(214, 214, 214) solid;
        border-radius: 10px;
        margin-bottom: 1.5rem;
        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
        rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    }
    .previousOrderPage__order_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* background-color: rgb(230, 230, 230); */
        background-color: #18a3ae;
        color: white;
        padding: 0.2rem 1rem;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    
    }
    .previousOrderPage__order_body{
        padding: 1rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .previousOrderPage__order_image{
        width: 5rem;
        height: 5rem;
        border-radius: 100%;
        border: 1px rgb(214, 214, 214) solid;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: #18A3AE; */
        margin-right: 1rem;
    }
}
/* @media only screen and (max-width: 450px){
    .previousOrderPage__order_image{
        width: 10rem;
        height: 6rem;
        border-radius: 100%;
        border: 1px rgb(214, 214, 214) solid;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
    }
} */