.orderDetailsPage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  padding: 3rem 6rem 5rem;
  flex-direction: row-reverse;
  align-items: flex-start;
  /* position: relative; */
}
.orderDetailsPage__productsList {
  flex: 0.6;
  justify-content: center;
  align-items: center;
}
.orderDetailsPage__product {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 1px rgb(214, 214, 214) solid;
  border-radius: 10px;
  margin-bottom: 1.3rem;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.orderDetailsPage__productImage {
  width: 100%;
  height: 100%;
}
.orderDetailsPage__productImage > img {
  width: 15rem;
  height: 12rem;
}
.orderDetailsPage__productDetails {
  /* padding-left: 80px; */
  text-align: left;
  margin-left: 50px;
}
.orderDetailsPage__right {
  flex: 0.3;
}
.orderDetailsPage__conformation {
  border-radius: 10px;
  width: 100%;
  min-height: 10vh;
  max-height: 30vh;
  padding: 10px 20px;
  border: 1px rgb(214, 214, 214) solid;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    margin-bottom: 1rem;
}
.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.orderDetailsPage__userDetails {
  border-radius: 10px;
  width: 100%;
  min-height: 50vh;
  padding: 10px 20px;
  border: 1px rgb(214, 214, 214) solid;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  margin-bottom: 1rem;
  
}
.orderDetailsPage__userDetails_heading {
  font-weight: 600;
  font-size: 1rem;
}
.button {
  background: #18a3ae;
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  /* margin-top: 33px; */
  cursor: pointer;
  
}
.orderDetailsPage__userDetails_address{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1000px){
  /*Tablets [601px -> 1200px]*/
  .orderDetailsPage {
    width: 100%;
    min-height: 110vh;
    display: flex;
    justify-content: center;
    padding: 5.5rem 6rem 5.5rem;
    flex-direction: column;
    align-items: center;
  }
  .orderDetailsPage__right {
    flex: 1;
    width: 100%;
    align-self: center;
  } 
  .orderDetailsPage__productsList {
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 600px){
/*Big smartphones [426px -> 600px]*/
.orderDetailsPage {
  width: 100%;
  min-height: 110vh;
  display: flex;
  justify-content: center;
  padding: 5rem 1rem 5rem;
  flex-direction: column;
  align-items: center;
  /* position: relative; */
}
.orderDetailsPage__right {
  /* position: relative; */
  flex: 1;
  position: relative;
    width: 100%;
  align-self: center;
  /* bottom: 5rem; */
  
}
.orderDetailsPage__productsList {
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.orderDetailsPage__productImage > img {
  width: 10rem;
  height: 10rem;
}
.orderDetailsPage__productDetails {
  /* padding-left: 80px; */
  flex: 1;
  text-align: left;
  margin-left: 50px;
}
.orderDetailsPage__productDetails {
  /* padding-left: 80px; */
  text-align: left;
  margin-left: 0;
}
}
/* @media only screen and (max-width: 425px){
Small smartphones [325px -> 425px]
} */