.checkoutPage {
  width: 100%;
  min-height: 80vh;
  padding: 3rem 6rem;
  overflow-x: hidden;
}
.checkoutPage__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkoutPage__boxs {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.checkoutPage__box {
  border-radius: 5px;
  width: 50vw;
}
.checkoutPage__boxTitle {
  background-color: #18a3ae;
  color: #fff;
  line-height: 1.5rem;
  font-size: 1.5rem;
  padding: 10px;
  font-weight: 600;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.checkoutPage__boxDetails {
  padding: 1rem 1rem 0.5rem;
  background-color: #eaeaea;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  /* text-align: center; */
}
.checkoutPage__boxTitle_bottomRadius {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.checkoutPage__buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem 0;
}
.checkoutPage__button {
  background: #18a3ae;
  /* display: inline-block; */
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  margin-top: 33px;
  cursor: pointer;
}
.checkoutPage__orderDetilas {
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  /* width: 50%; */
  /* height: 100%; */
}
.checkoutPage__orderDetilas_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.checkoutPage__input {
  background: #fbfbfb;
  width: 20%;

  border: none;
  outline: none;
  padding-top: 14px;
}

.qrCodeContainer {
  background-color: #18a3ae;
  padding: 1rem 2rem;
  border-radius: 10px;
  color: #eaeaea;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all 1s ease-in-out;
  /* width: 10rem;
  height: ; */
}
.qrCodeContainer__img {
  width: 30rem;
  height: 20rem;
  margin-bottom: 5rem;
  border-radius: 10px;
}
@media only screen and (max-width: 1000px) {
  /*Tablets [601px -> 1100px]*/
  .checkoutPage {
    width: 100vw;
    min-height: 80vh;
    padding: 3rem 0;
  }
  .checkoutPage__box {
    border-radius: 5px;
    width: 90vw;
  }
  .checkoutPage__buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  .checkoutPage__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
  .checkoutPage {
    width: 100vw;
    min-height: 80vh;
    padding: 3rem 1rem;
  }
  .checkoutPage__box {
    border-radius: 5px;
    width: 90vw;
  }
  .checkoutPage__buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  .checkoutPage__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .checkoutPage {
    width: 100vw;
    min-height: 80vh;
    padding: 3rem 1rem;
  }
  .checkoutPage__box {
    border-radius: 5px;
    width: 90vw;
  }
  .checkoutPage__buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  .checkoutPage__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
