.productDetailsPage{
    width: 100%;
    min-height: 80vh;
    padding: 3rem 6rem;
    overflow-x: hidden;
    /* background-color: #a7ebeb; */
}
.productDetail{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    /* height: 40rem; */
    /* height: min(50vh, 40rem); */
    height: auto;
    max-height: max-content;
    margin-bottom: 5rem;
    /* border: 1px solid rgb(150, 150, 150); */
    border-radius: 10px;
}
.productDetail__productImages{
    width: 60%;
    height: 100%;
    /* background-color: #f5f5f5; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
}
.productDetail__productImages__container{
    width: 25%;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* border: 1px solid rgb(150, 150, 150); */
    border-radius: 10px;
    align-items: center;
}
.productDetail__productImages__largeImage{
    width: 70%;
    height: 100%;
    /* background-color:bisque; */
    display: flex;
    /* flex-direction: column; */
    justify-content: space-evenly;
    /* border: 1px solid rgb(150, 150, 150); */
    align-items: center;
}
.productDetail__productImages__image{
    width: 100%;
    background-color: black;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
    /* margin-bottom: 0.5rem;
    margin-top: 0.5rem; */
}
.imgContainer{
    width: 100%;
    padding: 0.5rem;
    /* border-bottom: 1px solid rgb(150, 150, 150); */
}
.productDetail__text{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    text-align: left;
    /* background-color: #8a8a8a; */
}
.productDetail__text__name{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 3.5rem;
    text-transform: capitalize;

    color: #000000;
}
.productDetail__text__icon{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    /* width: ; */
    height: 2rem;
    text-align: left;
}
.productDetail__text__price{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 2rem;
line-height: 3rem;
text-align: center;
text-transform: capitalize;
text-align: left;
color: #000000;
}
.productDetail__text__price small {
    font-size: 70%;
    font-weight: 400;
    text-decoration: line-through;
    display: inline-block;
    margin-right: 0.2rem;
    color: #7a7a7a;
    
    padding-right: 0.5rem;
    padding-left: 1rem;
  }
  .productDetail__text__price span{
    font-size: 50%;
    color:  #FFD300;
    
  }
.productDetail__text__description{
    margin-top: 2rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
    text-transform: capitalize;

    color: #585858;
    /* flex: 1; */
}
.moreProducts{
    width: 100%;
    height: 25vh;
    background: #f1eeee;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.productDetail__add__container{
    width: 100%;
    margin-top: 3rem;
    text-align: center;
}
.productDetail__add{
    width: 30%;
}



/* Media Querys  */
@media only screen and (max-width: 950px){
    /*Tablets [801px -> 950px]*/
    .productDetail__text__name{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 3rem;
        text-transform: capitalize;
    
        color: #000000;
    }
    .productDetail__text__icon{
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        /* width: ; */
        height: 1.5rem;
        text-align: left;
    }
    .productDetail__text__price{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    text-transform: capitalize;
    text-align: left;
    color: #000000;
    }
    .productDetail__text__description{
        margin-top: 1.5rem;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 2rem;
        text-transform: capitalize;
    
        color: #585858;
        /* flex: 1; */
    }
    .productDetail__add__container{
        width: 100%;
        margin-top: 3rem;
        text-align: center;
    }
    .productDetail__add{
        width: 30%;
    }
    

}
@media only screen and (max-width: 800px){
	/*Big smartphones [426px -> 800px]*/
    .productDetail{
        flex-direction:column;
    }
    .productDetail__productImages{
        width: 100%;
    }
    .productDetail__text{
        width: 100%;
        margin-top:2rem;
        /* background-color: #8a8a8a; */
    }
}
@media only screen and (max-width: 615px){
	/*Small smartphones [325px -> 425px]*/
    .productDetailsPage{
        width: 100%;
        min-height: 80vh;
        padding: 3rem 2rem;
        overflow-x: hidden;
        /* background-color: #a7ebeb; */
    }
}