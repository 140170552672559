.invoice-container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem 0;
  /* width: 100vw; */

  border: 1px solid rgb(152, 151, 151);
}
