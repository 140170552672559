.socialIcon {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
}
.socialIcon:hover {
  color: white;
}
#social-links {
  display: flex;
}
.navbar {
  height: 6rem;
  padding: 0 6rem;
  transition: all 0.5s ease-in-out;
}
.navbarScroll {
  height: 5rem;
}

/* <----------------------------> */
ul {
  list-style: none;
  display: flex;
  align-items: center;
}
.display-picture {
  margin-left: auto;
}
.display-picture img {
  width: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.display-picture img:hover {
  border: ips solid rgb(249, 251, 251);
}
.cardProfile {
  transition: 0.5s ease;
}

.cardProfile ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background: rgb(252, 253, 253);
  position: absolute;
  top: 5rem;
  right: 5rem;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  border: 1px solid rgb(24, 163, 174);
}
.cardProfile ul li {
  list-style: none;
  padding: 5px 0;
  color: #fff;
  font-size: 14px;
}
.hidden {
  display: none;
}
.cardProfile a {
  list-style: none;
  text-decoration: none;
  color: black;
}
.cardProfile a:hover span:hover {
  color: rgb(24, 163, 174);
}
.cardProfile a,
span {
  color: black;
}
.linkTag {
  text-decoration: dotted;
  display: flex;
  align-items: center;
  justify-content: baseline;
}
#logout:hover {
  background-color: rgb(24, 163, 174);
  color: white;
}
#logout li {
  color: rgb(24, 163, 174);
}
#logout li:hover {
  /* background-color: rgb(24, 163, 174); */
  color: white;
}

/* Layout */

li {
  margin: 0 2rem;
  color: black;
}

.linkTag {
  display: block;
  position: relative;
  padding: 0.2em 0;
}
.linkTag:link {
  color: #18a3ae;
  background-color: transparent;
  text-decoration: none;
}

/* Fade in */
.linkTag::after {
  content: "";
  position: absolute;

  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: #18a3ae;

  opacity: 0;
  transition: opacity 500ms, transform 300ms;
  text-decoration: none;
}

.linkTag:hover::after,
.linkTag:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

/* Slide in */
.linkTag {
  overflow: hidden;
}

.linkTag::after {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.linkTag:hover::after,
.linkTag:focus::after {
  transform: translate3d(0, 0, 0);
}
.linkTag:hover {
  color: #18a3ae;
}

.cartIcon__container {
  position: relative;
  margin-right: 10px;
}
.cartLength__container {
  position: absolute;
  top: -4px;
  right: -10px;
  border-radius: 100%;
  background-color: #18a3ae;
  color: white;
  border: 1px solid white;
  width: 18px;
  height: 18px;
  font-size: 10px;
  z-index: 1;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cartLength__containe{
  position: absolute;
  /* top:-4px; */
  right: -10px;
  top: -4px;
  background-color: #18a3ae;
  height: 20px;
  font-size: 10px;
}
img {
  object-fit: contain;
}
/* <----------------------------> */

/* <----------------Media Queries for Medium  Device------------------> */
@media (max-width: 820px) {
  #social-links {
    display: none;
  }
}
/* <----------------Media Queries for small  Device------------------> */
@media (max-width: 600px) {
  .navbar {
    padding: 0 0.3rem;
  }
}

/* @media (max-width: 767px) {
  #social-links {
    display: none;
  }
} */
